.switch-container {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    &:last-child {
        margin-bottom: 0;
    }

    .switch {
        position: relative;
        display: inline-block;
        width: 60px;
        height: 34px;
    
        input {
            opacity: 0;
            width: 0;
            height: 0;
    
            &:checked {
    
                & + .slider {
                    background-color: $confirmButton;
    
                    &:before {
                        transform: translateX(26px);
                    }
                }
            }
    
            &:focus {
    
                & + .slider {
                    box-shadow: 0 0 1px $confirmButton;
                }
            }
        }
    
        .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #ccc;
            transition: all 200ms ease-in-out;
            border-radius: 34px;
    
            &:before {
                position: absolute;
                content: "";
                height: 26px;
                width: 26px;
                left: 4px;
                bottom: 4px;
                background-color: white;
                transition: all 200ms ease-in-out;
                border-radius: 50%;
            }
        }
    }

    .switch-content {
        margin-left: 16px;
        font-size: 180%;

        .title {
            font-size: 120%;
            margin-bottom: 0;
        }
    }
}