//colors
$blue: #3C54F0;
$light-blue: #00DFC1;
$red: #be3c3d;
$grey: #707171;
$white: #ffffff;
$pageBody: #FFFFFF;
$cancelButton: #707272;
$confirmButton: #00DFC1;
$black: #000000;
$medium-grey: #C5C5C5;
$light-grey: #FAFAFC;
$light-grey-hover: #eee;
$purple: #CE6CFF;
$orange: #FF7636;
$darkOrange: #CD4404;
$light-orange: #FFCE6C;
$light-red: #f9b5b5;
$lavender: #815FF6;

//dark mode colours
$pageBodyDark: #000000;
$dark-box-background: #181818;
$dark-sub-text: #f4f4f4;
$light-text: rgba(255, 255,255, 0.9);
$dark-mode-blue: lighten($blue, 10%);

//password-meter
$meter-weak: #CE6CFF;
$meter-fair: #FF9046;
$meter-good: #3C54F0;
$meter-strong: #00DFC1;

//fonts
@font-face {
    font-family: 'Source Sans Pro';
    src: url("../fonts/SourceSansPro-Regular.ttf");
}

@font-face {
    font-family: 'ES Build';
    src: url("../fonts/ES-Build-Medium.woff2") format("woff2"),
    url("../fonts/ES-Build-Medium.woff") format("woff"),
    url("../fonts/ES-Build-Medium.ttf") format("ttf"),
    url("../fonts/ES-Build-Medium.eot") format("eot"),
    url("../fonts/ES-Build-Medium.otf") format("otf");
}

$source-sans-pro: 'Source Sans Pro', sans-serif;
$dm-sans: 'DM Sans', sans-serif;
$es-build: 'ES Build', sans-serif;

@mixin source-sans-pro-regular() {
    font-family: $source-sans-pro;
    font-weight: 400;
}

@mixin source-sans-pro-semi-bold() {
    font-family: $source-sans-pro;
    font-weight: 600;
}

@mixin source-sans-pro-bold() {
    font-family: $source-sans-pro;
    font-weight: 700;
}

@mixin source-sans-pro-black-italic() {
    font-weight: 900;
    font-style: italic;
}

@mixin dm-sans-regular() {
    font-family: $dm-sans;
    font-weight: 400;
    letter-spacing: 0;
}

@mixin dm-sans-medium() {
    font-family: $dm-sans;
    font-weight: 500;
}

@mixin dm-sans-bold() {
    font-family: $dm-sans;
    font-weight: 700;
}

@mixin es-build-regular() {
    font-family: $es-build;
    font-weight: 400;
}

@mixin es-build-medium() {
    font-family: $es-build;
    font-weight: 500;
}

@mixin es-build-bold() {
    font-family: $es-build;
    font-weight: 700;
}

//centering
@mixin centered-content(){
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@mixin vertical-center(){
    top: 50%;
    transform: translateY(-50%);
}

@mixin horizontal-center(){
    left: 50%;
    transform: translateX(-50%);
}

//rounded corners
@mixin rounded-all($radius: 5px){
    border-radius: $radius;
}
@mixin rounded-top($radius: 5px){
    border-radius: $radius $radius 0 0;
}
@mixin rounded-bottom($radius: 5px){
    border-radius: 0 0 $radius $radius;
}
@mixin rounded-left($radius: 5px){
    border-radius: $radius 0 0 $radius;
}
@mixin rounded-right($radius: 5px){
    border-radius: 0 $radius $radius 0;
}

@mixin last-child {
    &:last-child {
        @content;
    }
}

@mixin contentMarginBottom {
    margin-bottom: 18px;
}

//dimensions
@mixin fullPageDimensions() {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

@mixin hover() {
    @media (hover: hover) {
        &:hover {
            @content;
        }
    }
}

@mixin gradient($color1, $color2, $fallback) {
    background: $fallback;
    background: -moz-linear-gradient(45deg, $color1 0%, $color2 100%);
    background: -webkit-linear-gradient(45deg, $color1 0%, $color2 100%);
    background: linear-gradient(45deg, $color1 0%, $color2 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#{$color1}",endColorstr="#{$color2}",GradientType=1);
}

@mixin box-shadow() {
    -webkit-box-shadow: 0px 5px 12px -1px rgba(0,0,0,0.39); 
    box-shadow: 0px 5px 12px -1px rgba(0,0,0,0.39);
}